import React 			from "react"
import Layout			from "../components/layout"
//import Para       from "../components/paragraph"


const Datenschutz = () => (
	<Layout head_gif = "datenschutz_bhb.png">
  	<h1 align="center">Datenschutz</h1>
		<div className="row">
			 <p>
			 <b>Datenschutzerklärung</b><br />
			 Aufgrund der im Mai 2018 in Kraft getretenen EU-DSGVO werden die Datenschutzrichtlinien hier angef&uuml;hrt.
			 Das Ziel der EU-Grundverordnung besteht darin, den Besucher dieser Website über die Verwendung personenbezogener Daten, Umfang, Zweck und
			 Art der Erhebung dieser Daten zu informieren. Grunds&auml;tzlich ist anzumerken, dass der Webseitenbetreiber entsprechend den gesetzlichen
			 Vorgaben und mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt die Daten behandelt. Dennoch ist zu bedenken, dass bei der Daten&uuml;bertragung
			 im Internet grunds&auml;tzlich Sicherheitsl&uuml;cken vorliegen k&ouml;nnen. Ein absoluter Schutz durch Fremde ist nicht realisierbar.
			 <br />
			 <b>Datenerfassung und Datenverwendung</b><br />
			 Der Kontakt kann, soweit vorhanden, mittels Anfrage-, Kontakt-, Buchungs-, Bestell- oder Newsletterformular erfolgen.
			 Dabei werden die dort eingetragenen bzw. angegebenen personenbezogenen Daten gespeichert, um Ihr Anliegen zu bearbeiten, Ihnen alle
			 Informationen zu übermitteln.
			 Falls die Angabe der Daten über die obigen Wege nicht erwünscht ist, kann die Kontaktaufnahme auch per E-Mail,
			 Telefon oder Post erfolgen. In jedem Falle werden Ihre Daten vertraulich behandelt und unterliegen dem Datenschutz.
			 Mit Aufruf unserer Website werden automatisch überwiegend technische Daten zum Zwecke statistischer Auswertungen, zur Verbesserung der
			 Website und der Sicherheit unseres IT-System &uuml;bermittelt.
			 Folgende Daten werden bei diesem Vorgang gespeichert: IP-Adresse, Betriebssystem, Webbrowser, Datum, Uhrzeit, Aufenthaltsdauer, Art der Dateien, besuchte Seiten, Herkunft der Besucher, Suchausdr&uuml;cke und Suchbegriffe (&uuml;ber Suchmaschinen). Diese Daten werden nicht an andere fremde Server &uuml;bermittelt.
			 <br />
			 <b>Weitergabe der Daten</b><br />
			 Personenbezogene Daten werden nur weiter gegeben, sofern wir dazu berechtigt sind. In bestimmten Fällen, z.B. bei Abwicklung von Zahlungen
			 über Kreditkarten-Unternehmen, für die Buchhaltung, Strafverfolgungsverfahren, ist eine Weitergabe unumgänglich.
			 In anderen Fällen werden wir Sie bei Weitergabe der personenbezogenen Daten an Dritte informieren, bzw. soweit erforderlich, Ihre Zustimmung einholen.
			 <br />
			 <b>Verantwortlichkeit</b><br />
			 Der Website-Betreiber ist für die Datenverarbeitung und den Datenschutz auf dieser Website verantwortlich.
			 Die Kontaktdaten hierfür entnehmen Sie bitte dem Impressum.
			 <br />
			 <b>Ihre Rechte</b><br />
			 Es ist Ihr Recht, jederzeit Informationen über Ihre gespeicherten, personenbezogenen Daten zu erhalten.
			 Ebenso können Sie verlangen, diese Daten zu löschen oder zu aktualisieren.
			 Löschungen können jedoch nur im Einklang mit steuerrechtlichen und handelsrechtlichen Gesetzen erfolgen.
			 Hierbei sind vor allem die Aufbewahrungspflichten seitens des Website-Betreibers zu beachten.
			 In diesem Zusammenhang steht Ihnen auch ein Beschwerderecht zu, das Sie ggf. bei der zuständigen Aufsichtsbehörde in Anspruch nehmen
			 können.
			 <br />
			 <b>Cookies</b><br />
			 Auf unserer Website werden keine Cookies verwendet.
			 <br />
			 <b>Google Web Fonts</b><br />
			 Zur Verbesserung der Darstellung unserer Website benutzen wir Google Web Fonts.
			 Bei Aufruf unserer Seiten wird eine Verbindung zu den Google-Servern hergestellt.
			 Hierbei wird die IP-Adresse an Google übermittelt. Sofern Ihr Browser Google Web Fonts nicht unterstützt, erfolgt die Darstellung
			 einer Standardschrift.
			 <br />
			 <b>Google Maps</b><br />
			 Diese Website verwendet den Kartendienst Google Maps API, der von der Firma
			 Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA zur Verfügung gestellt wird.
			 Google Maps dient zur schnellen Lokalisierung unseres Betriebes und dient deshalb der Verbesserung unserer Website.
			 Hierbei werden Daten, vornehmlich die IP-Adresse, zum Google-Server &uuml;bertragen. Auf die Übertragung der Daten haben wir als
			 Website-Betreiber keinen Einfluss..
			 <br />
			 </p>
	  </div>
	</Layout>
);

export default Datenschutz;
